export type SectionParameters = {
	extensionTitle: string;
	backgroundColor: string;
	backgroundImageSrc: string;
	backgroundComposition: SectionBackgroundComposition;
};

export type SectionActions = {
	updateBackgroundColor: (backgroundColor: SectionParameters['backgroundColor']) => void;
	updateBackgroundImageSrc: (backgroundImageSrc: SectionParameters['backgroundImageSrc']) => void;
	updateBackgroundComposition: (
		backgroundComposition: SectionParameters['backgroundComposition'],
	) => void;
};

// TODO: Remove SectionBackgroundComposition and use the type from the reusable components
export enum SectionBackgroundComposition {
	FILL = 'fill',
	FIT = 'fit',
	CROP = 'crop',
}
