import { type ExtensionManifest } from '@atlaskit/editor-common/extensions';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import { fg } from '@atlaskit/platform-feature-flags';
import { parse } from '@atlassian/cs-ari';
import { getForgeUIExtensionsAsync } from '@atlassian/forge-ui/provider';
import { ForgeExtensionProvider } from '../forgeExtensionProvider';
import { EXTENSION_NAMESPACE, MODULE_NAME } from '../../utils/constants';
import {
	CONFIG_USER_PICKER_PROVIDER,
	validateForgeConfigPayload,
	renderConfigHelper,
	transformConfigBefore,
	transformConfigAfter,
} from '../../config';
import { maybeUpgradeConnectExtensionToForge } from './upgradeConnectNodeToForge';
import {
	type ForgeExtensionProviderSharedParams,
	type ForgeExtension,
	type ForgeExtensionManifest,
	type ForgeExtensionParameters,
	type UpdateFunction,
} from '../../types';
import { AnalyticsAction, createAnalyticsClient } from './analytics';
import {
	getExtensionManifestMapper,
	manifestIcons,
	updateNodeWithPayload,
} from './convertToExtensionManifest';

// We keep track of the reason that an extension failed to upgrade, for analytics purposes
const connectToForgeBlockedExtensions = new Set();

export async function getForgeExtensionProviderSharedRefactor({
	accountId,
	apolloClient,
	cloudId,
	contextIds,
	forgeUIAnalyticsContext,
	product,
	analyticsWebClient,
	createRenderFunction,
	getFieldsDefinitionFunction,
	connectExtensionProvider,
	editorActions,
	extensionsFilter,
	dataClassificationTagIds,
	locale,
}: ForgeExtensionProviderSharedParams) {
	let extensionProvider: ForgeExtensionProvider;
	const { extensions } = await getForgeUIExtensionsAsync({
		client: apolloClient,
		contextIds,
		moduleType: MODULE_NAME,
		expandAppOwner: true,
		extensionsFilter,
		dataClassificationTagIds,
		locale,
	});

	const eventSource = editorActions ? 'editPageScreen' : 'viewPageScreen';

	const analyticsClient = createAnalyticsClient({
		analyticsWebClient,
		forgeUIAnalyticsContext,
		source: eventSource,
	});
	const { sendEvent } = analyticsClient;

	const updateFunction: UpdateFunction = async (
		parameters,
		actions,
		extension,
		hasCustomConfig,
	) => {
		await maybeUpgradeConnectExtensionToForge({
			actions,
			analyticsClient,
			connectToForgeBlockedExtensions,
			editorActions,
			extensionProvider,
		});

		const node = editorActions?.getSelectedNode();

		const isBodiedExtension = node?.type.name === 'bodiedExtension';
		if (node && extension && actions && (hasCustomConfig || isBodiedExtension)) {
			/**
			 * Converts a ProseMirror Node (received by EditorActions API) to a ForgeExtension (provided to render function)
			 * This includes:
			 * - Extracting the node's attributes
			 * - Extracting the node's type
			 * - Transforming the node's content from ProseMirror to ADF JSON (and handling any errors)
			 */
			const forgeExtensionNode = (() => {
				const forgeExtension = {
					...node.attrs,
					type: node.type.name,
				} as ForgeExtension;

				try {
					const { content } = new JSONTransformer().encode(node);
					return { ...forgeExtension, content };
				} catch (error: unknown) {
					// failed to encode node to JSON
					// continue rendering custom config without content and emit analytics error event
					const { localId } = node?.attrs;
					sendEvent(AnalyticsAction.FORGE_EXTENSION_FAILED, {
						extension,
						error,
						localId,
					});
					return forgeExtension;
				}
			})();

			// No need to wait for config to render to start showing the panel (for non-custom config)
			// noinspection ES6MissingAwait
			renderConfigHelper({
				extension,
				forgeExtensionNode,
				hasCustomConfig: Boolean(hasCustomConfig),
				isInitialInsertion: false,
				createRenderFunction,
				onSubmit: (payload) => updateNodeWithPayload(actions, node.attrs.localId, payload),
				onValidate: (data) =>
					validateForgeConfigPayload(data, {
						isBodiedExtension,
						isInitialInsertion: false,
						schema: editorActions?._privateGetEditorView?.()?.state.schema,
						useStrictParameterValidation: fg('enable-strict-forge-macro-parameter-validation'),
					}),
			});
		}

		if (!hasCustomConfig) {
			return actions!.editInContextPanel(transformConfigBefore, async (params) =>
				transformConfigAfter(params),
			);
		}
	};

	const fieldsModules: ExtensionManifest<ForgeExtensionParameters>['modules']['fields'] = {
		user: {
			[CONFIG_USER_PICKER_PROVIDER]: {
				provider: async () => ({
					siteId: cloudId,
					// If principalId === 'Context', upstream attempts to use other context to discover the principal ID
					principalId: accountId || 'Context',
					fieldId: 'forgeConfigUserPicker',
					productKey: product as 'confluence',
				}),
			},
		},
	};

	const convertToExtensionManifest = getExtensionManifestMapper({
		analyticsClient,
		editorActions,
		eventSource,
		fieldsModules,
		renderConfigHelper,
		createRenderFunction,
		connectToForgeBlockedExtensions,
		getFieldsDefinitionFunction,
		updateFunction,
	});

	const extensionManifests: ForgeExtensionManifest[] = (extensions || [])
		.filter(({ id }) => parse(id).resourceId !== undefined)
		.map(convertToExtensionManifest);

	const legacyMacroManifest: ExtensionManifest<ForgeExtensionParameters> = {
		/**
		 * title, description and icons are required but will not be used anywhere,
		 * since this manifest is used solely for rendering legacy nodes
		 **/
		title: 'Forge UI Macro',
		description: 'Supports existing Forge UI Macro nodes',
		type: EXTENSION_NAMESPACE,
		key: 'xen:macro',
		icons: manifestIcons,
		modules: {
			nodes: {
				default: {
					type: 'extension',
					render: createRenderFunction,
					update: updateFunction,
					getFieldsDefinition: getFieldsDefinitionFunction,
				},
			},
			fields: fieldsModules,
		},
	};

	const manifests = [legacyMacroManifest, ...extensionManifests];
	extensionProvider = new ForgeExtensionProvider(
		manifests,
		connectExtensionProvider,
		undefined,
		analyticsClient,
	);
	return extensionProvider;
}
