import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

export type SpotlightContentProps = {
	extensionLocalId: string;
	parameters: SpotlightParameters;
	isInViewMode?: boolean;
	contentId: string;
	experienceName: string;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
};

export type SpotlightParameters = {
	extensionTitle: string;
	height: SpotlightHeight;
	backgroundColor: string;
	cornerStyle: SpotlightCornerStyle;
	layout: SpotlightLayout;
	content: {
		text: {
			title?: string;
			description?: string;
			color?: string;
		};
		button: {
			isButtonShown: boolean;
			text?: string;
			link: string;
			style: SpotlightButtonStyle;
		};
		image: {
			imageSrc?: string;
			altText?: string;
		};
	};
};

export type SpotlightActions = {
	updateHeight: (newHeight: SpotlightParameters['height']) => void;
	updateLayout: (newLayout: SpotlightParameters['layout']) => void;
	updateBackgroundColor: (newBackgroundColor: SpotlightParameters['backgroundColor']) => void;
	updateContentTextTitle: (newTitle: SpotlightParameters['content']['text']['title']) => void;
	updateContentTextDescription: (
		newDescription: SpotlightParameters['content']['text']['description'],
	) => void;
	updateContentTextColor: (newTextColor: SpotlightParameters['content']['text']['color']) => void;
	updateContentIsButtonShown: (
		isButtonShown: SpotlightParameters['content']['button']['isButtonShown'],
	) => void;
	updateContentButtonText: (
		newButtonText: SpotlightParameters['content']['button']['text'],
	) => void;
	updateContentButtonLink: (
		newButtonLink: SpotlightParameters['content']['button']['link'],
	) => void;
	updateContentButtonStyle: (
		newButtonStyle: SpotlightParameters['content']['button']['style'],
	) => void;
	updateContentImage: (
		newImageSrc?: SpotlightParameters['content']['image']['imageSrc'],
		newAtText?: SpotlightParameters['content']['image']['altText'],
	) => void;
};

export type SpotlightParameterPaths =
	| 'height'
	| 'backgroundColor'
	| 'layout'
	| 'cornerStyle'
	| 'content.text.title'
	| 'content.text.description'
	| 'content.text.color'
	| 'content.button.isButtonShown'
	| 'content.button.text'
	| 'content.button.link'
	| 'content.button.style'
	| 'content.image.url'
	| 'content.image.altText';

export enum SpotlightHeight {
	MEDIUM = 'medium',
	SMALL = 'small',
	LARGE = 'large',
}

export enum SpotlightCornerStyle {
	ROUNDED = 'rounded',
	SQUARED = 'squared',
}

export enum SpotlightLayout {
	SPLIT_IMAGE_RIGHT = 'split-image-right',
	SPLIT_IMAGE_LEFT = 'split-image-left',
	FULL_IMAGE_BACKGROUND = 'full-image-background',
	NO_IMAGE_BACKGROUND = 'no-image-background',
}

export enum SpotlightButtonStyle {
	DEFAULT = 'default',
	ALTERNATIVE_1 = 'alternative_1',
}
