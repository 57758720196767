import React from 'react';

import type { ExtensionHandlers, ExtensionParams } from '@atlaskit/editor-common/extensions';

import { fg } from '@confluence/feature-gating';

import { linkCardsExtensionType } from '../link-cards/linkCardsExtensionType';
import { spotlightExtensionType } from '../spotlight/spotlightConstants';
import { LinkCardsLoader } from '../link-cards/view/LinkCardsLoader';
import type { LinkCardsParameters } from '../link-cards/linkCardsTypes';
import type { SpotlightParameters } from '../spotlight/spotlightTypes';
import { carouselExtensionType } from '../carousel/carouselConstants';
import { CarouselLoader } from '../carousel/view/CarouselLoader';
import { linksMenuExtensionType } from '../links-menu/linksMenuExtensionType';
import { LinksMenuLoader } from '../links-menu/view/LinksMenuLoader';
import type { LinksMenuParameters } from '../links-menu/linksMenuTypes';
import { SpotlightLoader } from '../spotlight/view/SpotlightLoader';
import type { SectionParameters } from '../section/sectionTypes';
import { sectionExtensionType } from '../section/sectionConstants';
import { SectionLoader } from '../section/view/SectionLoader';

type getRendererCustomSitesExtensionHandlersProps = {
	contentId: string | undefined;
};

export const getRendererCustomSitesExtensionHandlers = ({
	contentId,
}: getRendererCustomSitesExtensionHandlersProps): ExtensionHandlers => {
	const extensionHandlers: ExtensionHandlers = {
		[linkCardsExtensionType]: () => <></>,
		[carouselExtensionType]: () => <></>,
		[linksMenuExtensionType]: () => <></>,
		[spotlightExtensionType]: () => <></>,
		[sectionExtensionType]: () => <></>,
	};

	if (contentId && fg('company_hub_beta')) {
		extensionHandlers[linkCardsExtensionType] = (
			extension: ExtensionParams<LinkCardsParameters>,
		) => <LinkCardsLoader extensionNode={extension} contentId={contentId} />;
		extensionHandlers[carouselExtensionType] = (
			extension: ExtensionParams<LinkCardsParameters>,
		) => <CarouselLoader extensionNode={extension} contentId={contentId} />;
		if (fg('company_hub_links_menu_extension')) {
			extensionHandlers[linksMenuExtensionType] = (
				extension: ExtensionParams<LinksMenuParameters>,
			) => <LinksMenuLoader extensionNode={extension} contentId={contentId} />;
		}
		if (fg('company_hub_spotlight_extension')) {
			extensionHandlers[spotlightExtensionType] = (
				extension: ExtensionParams<SpotlightParameters>,
			) => <SpotlightLoader extensionNode={extension} contentId={contentId} />;
		}
		if (fg('company_hub_section_extension')) {
			extensionHandlers[sectionExtensionType] = (extension: ExtensionParams<SectionParameters>) => (
				<SectionLoader extensionNode={extension} contentId={contentId} />
			);
		}
	}
	return extensionHandlers;
};
