import React from 'react';
import type { IntlShape } from 'react-intl-next';
import { defineMessages } from 'react-intl-next';

import type { EditorActions } from '@atlaskit/editor-core';
import { DefaultExtensionProvider } from '@atlaskit/editor-common/extensions';

import { LoadableAfterPaint } from '@confluence/loadable';

import { getExtensionManifest } from '../shared-components/customSitesManifestBuilder';

import type { SectionParameters } from './sectionTypes';
import { SectionBackgroundComposition } from './sectionTypes';
import { sectionExtensionType } from './sectionConstants';
import type { SectionExtensionProps } from './SectionExtension';

const SectionExtension = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SectionExtension" */ './SectionExtension'))
			.SectionExtension,
});

const i18n = defineMessages({
	title: {
		id: 'custom-sites-extensions.section.manifest.extension-title',
		defaultMessage: 'Section',
		description: 'Title of section extension to be displayed in toolbar/shortcut macros dropdown.',
	},
	description: {
		id: 'custom-sites-extensions.section.manifest.macro-description',
		defaultMessage: 'Group elements together with a background',
		description:
			'Description of section extension to be displayed in toolbar/shortcut macros dropdown',
	},
});

const sectionParameters = (intl: IntlShape): SectionParameters => ({
	extensionTitle: intl.formatMessage(i18n.title),
	backgroundColor: '#FFFFFF',
	backgroundImageSrc: '',
	backgroundComposition: SectionBackgroundComposition.FIT,
});

type SectionManifestArguments = {
	editorActions?: EditorActions;
	openConfigPanel?: (localId: string) => void;
	intl: IntlShape;
} & Pick<SectionExtensionProps, 'contentId' | 'createAnalyticsEvent'>;

export const sectionExtensionProvider = async ({
	editorActions,
	openConfigPanel,
	intl,
	...extensionPassThroughProps
}: SectionManifestArguments) => {
	const title = intl.formatMessage(i18n.title);
	const description = intl.formatMessage(i18n.description);
	const parameters = sectionParameters(intl);
	const type = sectionExtensionType;

	// TODO: Ask Michelle for section icon and replace carousel icon
	const icons = {
		'48': () =>
			import(
				/* webpackChunkName: "loadable-SectionExtensionIcon" */
				'@confluence/icons/entry-points/CarouselMacroIcon'
			).then((mod) => mod.CarouselMacroIcon),
	};

	const update = async () => {
		const selectedNode = editorActions?.getSelectedNode()?.toJSON();
		const selectedLocalId = selectedNode?.attrs?.localId;

		if (!!selectedLocalId && openConfigPanel) {
			openConfigPanel(selectedLocalId);
		}
	};

	const render =
		() =>
		({ node }) => (
			<SectionExtension
				extensionNode={node}
				editorActions={editorActions}
				{...extensionPassThroughProps}
			/>
		);

	return new DefaultExtensionProvider([
		getExtensionManifest<SectionParameters>({
			key: 'section',
			icons,
			parameters,
			update,
			render,
			title,
			type,
			description,
			nodeType: 'multiBodiedExtension',
			source: 'premiumSectionInsert',
		}),
	]);
};
